import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { PageError, PageMode } from "../Pages";
import Loading from "../../components/Loading";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { useGetPage } from "../../hooks/page";
import useTrackEvent from "../../hooks/useTrackEvent";
import bulkPageReducer from "../../reducers/bulkPageReducer";
import { parseUrlParams, buildOrUpdateTag } from "../../support/helpers";
import { setIsFetchingPage, setPageError } from "../../reducers/rootReducer";
import ComingSoon from "../ComingSoon";
import { useQueryParam, BooleanParam } from "use-query-params";
import NotFound from "../NotFound";
import PageErrorBoundry from "./PageErrorBoundry";
import { useSearchData } from "../../hooks/useSearchData";
import { useTrackUser } from "../../hooks/useTrackUser";

/**
 * Main page wrapper. This will fetch the page when the app is served up locally.
 */
function PageHandler({ children }) {
    const dispatch = useDispatch();
    useTrackUser();
    const { pathname, search } = useLocation();
    const [isMapVisible] = useQueryParam("mapVisible", BooleanParam);
    const sessionID = useSelector((state) => state.root.userSession.sessionID);
    const styling = useSelector((state) => state.root.styling);
    const mode = useSelector((state) => state.root.mode);
    const ssr = useSelector((state) => state.root.ssr);
    const isMobile = useSelector((state) => state.root.isMobile);
    const pageError = useSelector((state) => state.root.pageError);
    const pageHeaderInfo = useSelector((state) => state.pages.pageHeaderInfo);
    const history = useHistory();
    const isHomePage = pathname === "/";
    const [isLoading, setIsLoading] = useState(!ssr && !styling);
    const { execute, error: pageRequestError } = useGetPage();
    const { trackEvent } = useTrackEvent();
    const isFirstRender = useRef(true);

    useEffect(() => {
        if (!styling && sessionID) {
            const fetchData = async () => {
                dispatch(setIsFetchingPage(true));
                try {
                    bulkPageReducer(
                        dispatch,
                        await execute(pathname, parseUrlParams(search)),
                        history,
                        pathname
                    );
                } finally {
                    setIsLoading(false);
                }
                dispatch(setIsFetchingPage(false));
            };
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionID]);

    useEffect(() => {
        if (!isFirstRender.current) {
            if (pathname) {
                trackEvent("PAGEVIEW", { SOURCE_INFO: pathname });
            }
        } else {
            isFirstRender.current = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    useEffect(() => {
        if (pageRequestError) {
            dispatch(
                setPageError({ page: "Page", error: "Error loading Page" })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageRequestError]);

    useEffect(() => {
        if (pageHeaderInfo) {
            const currentParams = parseUrlParams(search);
            const pageNumberString = currentParams?.page
                ? `- Page ${currentParams?.page}`
                : "";

            if (pageHeaderInfo?.title)
                document.title = `${pageHeaderInfo.title} ${pageNumberString}`;
            if (pageHeaderInfo?.canonical) {
                const canonicalPageNumberString = currentParams?.page
                    ? `?page=${currentParams?.page}`
                    : "";
                buildOrUpdateTag(
                    "link",
                    "rel",
                    "canonical",
                    "href",
                    `${pageHeaderInfo.canonical}${canonicalPageNumberString}`
                );
            }
            if (pageHeaderInfo?.metaAndScripts) {
                pageHeaderInfo.metaAndScripts.forEach((metaData) => {
                    const { tagname, attributes } = metaData?.element || {};
                    let content = attributes.content;
                    if (attributes?.name) {
                        if (attributes.name === "description")
                            content = `${content} ${pageNumberString}`;
                        buildOrUpdateTag(
                            tagname,
                            "name",
                            attributes.name,
                            "content",
                            content
                        );
                    } else if (attributes?.property) {
                        if (attributes.property === "og:description")
                            content = `${content} ${pageNumberString}`;
                        buildOrUpdateTag(
                            tagname,
                            "property",
                            attributes.property,
                            "content",
                            content
                        );
                    }
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageHeaderInfo, search]);

    //TODO: add in page not found handling
    return (
        <div
            className={`container-fluid g-0 ${!isHomePage ? "bg-light" : ""} ${
                isMobile ? "mobile" : ""
            }`}
            style={
                isMapVisible && isMobile
                    ? { overflowY: "hidden", height: "100vh" }
                    : {}
            }
        >
            <ComingSoon>
                <div
                    className="d-flex flex-column align-items-start"
                    style={{ minHeight: "100vh" }}
                >
                    <Header hasError={pageError !== null} />
                    <PageErrorBoundry>
                        <div className={`page w-100`}>
                            <NotFound>
                                {isLoading ? (
                                    <Loading />
                                ) : pageError ? (
                                    <PageError />
                                ) : mode &&
                                  mode !== "normal" &&
                                  mode !== "edit" ? (
                                    <PageMode />
                                ) : (
                                    children
                                )}
                            </NotFound>
                        </div>
                    </PageErrorBoundry>
                    <Footer />
                </div>
            </ComingSoon>
        </div>
    );
}

export default PageHandler;
